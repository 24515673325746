/* Research Content Container */
.research-content {
  margin-bottom: var(--space-xl);
}

/* Section Title Styling */
.section-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  border-bottom: 1px solid var(--border-light);
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
  font-size: var(--font-size-xl);
  padding-bottom: 0.5rem;
}

/* Text Styling */
.research-text, .area-description, .sub-area-text, .implementation-text, .conclusion-text {
  font-size: var(--font-size-md);
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: var(--space-md);
  color: var(--secondary-text);
}

/* Section Containers */
.research-intro, .research-areas, .current-projects {
  padding: var(--space-md);
  background-color: var(--highlight-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--space-lg);
  width: 100%;
}

/* Research Area Cards */
.research-detail-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  overflow: hidden;
  margin-bottom: var(--space-md);
}

.sub-area-card {
  background-color: var(--secondary-bg);
  border: 1px solid var(--border-light);
  box-shadow: none;
}

.area-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  margin-bottom: var(--space-md);
  font-size: var(--font-size-lg);
  color: var(--accent-color);
  border-bottom: 1px dashed var(--border-light);
  padding-bottom: 5px;
}

.sub-area-title {
  font-family: var(--font-family-serif);
  font-weight: 600;
  margin-bottom: var(--space-sm);
  font-size: calc(var(--font-size-md) + 0.1rem);
}

/* Image Styling */
.area-image {
  max-width: 100%;
  height: auto;
  max-height: 180px;
  margin: 0 auto var(--space-sm);
  padding: 5px;
  background-color: white;
  border: 1px solid var(--border-light);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--card-shadow);
}

.image-caption {
  font-size: 0.8rem;
  color: var(--secondary-text);
  text-align: center;
  margin-top: 5px;
  font-style: italic;
}

/* Publications Styling */
.research-publications {
  margin-top: var(--space-md);
  padding-top: var(--space-sm);
}

.research-publications-title {
  font-family: var(--font-family-serif);
  font-weight: 600;
  font-size: var(--font-size-md);
  margin-bottom: var(--space-sm);
  color: var(--accent-color);
}

.research-publications-list {
  padding-left: 1.25rem;
  margin-bottom: var(--space-md);
}

.research-publications-list li {
  margin-bottom: var(--space-sm);
  font-size: 0.9rem;
  line-height: 1.5;
}

.research-publications-list a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: 500;
}

.research-publications-list a:hover {
  text-decoration: underline;
}

.research-publications-venue {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 6px;
  font-size: 0.7rem;
  font-weight: 600;
  color: white;
  background-color: #007bff;
  border-radius: 4px;
  vertical-align: middle;
}

/* Current Projects */
.project-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  overflow: hidden;
}

.project-card:hover {
  transform: var(--hover-lift);
  box-shadow: var(--card-shadow-hover);
}

.project-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: var(--space-sm);
  color: var(--accent-color);
}

.project-text {
  font-size: var(--font-size-sm);
  line-height: 1.5;
  color: var(--secondary-text);
}

.project-status {
  display: inline-block;
  margin-top: var(--space-sm);
  padding: 3px 8px;
  background-color: #28a745;
  color: white;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: var(--border-radius-sm);
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .section-title {
    font-size: var(--font-size-lg);
  }
  
  .research-text, .area-description, .sub-area-text, .implementation-text, .conclusion-text {
    font-size: var(--font-size-sm);
  }
  
  .research-intro, .research-areas, .current-projects {
    padding: 0.75rem;
  }
  
  .area-title {
    font-size: 1.2rem;
  }
  
  .sub-area-title {
    font-size: 1rem;
    margin-top: var(--space-sm);
  }
  
  .research-publications-list li {
    font-size: 0.85rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .research-content {
    padding: 0 var(--space-md);
  }
}

@media (min-width: 992px) {
  .research-intro, .research-areas, .current-projects {
    padding: var(--space-lg);
  }
  
  .area-image {
    max-height: 200px;
  }
}
