/* CV Content Container */
.cv-content {
  margin-bottom: var(--space-xl);
}

/* CV Header Styling */
.cv-header-container {
  text-align: center;
  padding: var(--space-md);
  background-color: var(--highlight-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--space-lg);
}

.cv-main-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 0.25rem;
}

.cv-subtitle {
  color: var(--secondary-text);
  font-size: 1rem;
  margin-bottom: var(--space-md);
}


/* Section Styling */
.cv-section {
  padding: var(--space-md);
  background-color: var(--highlight-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--space-lg);
}

.section-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  border-bottom: 1px solid var(--border-light);
  margin-top: var(--space-sm);
  margin-bottom: var(--space-md);
  font-size: var(--font-size-xl);
  padding-bottom: 0.5rem;
}

.section-title.small {
  font-size: var(--font-size-md);
}

.section-content {
  padding: var(--space-sm);
}

/* Year Badges */
.year-badge {
  background-color: var(--accent-color);
  color: white;
  padding: 4px 10px;
  border-radius: var(--border-radius-sm);
  font-size: 0.8rem;
  font-weight: 500;
}

/* Education Cards */
.education-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  margin-bottom: var(--space-md);
}

.education-card:hover {
  transform: var(--hover-lift-sm);
  box-shadow: var(--card-shadow-hover);
}

.institution-name {
  font-family: var(--font-family-serif);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: var(--space-sm);
  color: var(--accent-color);
}

.institution-icon {
  max-width: 100%;
  height: auto;
  max-height: 60px;
  border-radius: var(--border-radius-sm);
  object-fit: contain;
}

.institution-icon-placeholder {
  width: 50px;
  height: 50px;
  background-color: var(--accent-color);
  color: white;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 0.9rem;
}

.degrees-list {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.degree-item {
  margin-bottom: 5px;
  font-style: italic;
}

.degree-icon {
  margin-right: 8px;
  color: var(--accent-color);
}

.advisor-info {
  margin-top: var(--space-sm);
  font-size: 0.9rem;
  color: var(--secondary-text);
}

/* Experience Cards */
.simplified-experience-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  margin-bottom: var(--space-md);
}

.simplified-experience-card:hover {
  transform: var(--hover-lift-sm);
  box-shadow: var(--card-shadow-hover);
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--space-sm);
}

.employer-name {
  font-family: var(--font-family-serif);
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--accent-color);
}

.employer-icon {
  max-width: 100%;
  height: auto;
  max-height: 60px;
  border-radius: var(--border-radius-sm);
  object-fit: contain;
}

.employer-icon-placeholder {
  width: 50px;
  height: 50px;
  background-color: var(--accent-color);
  color: white;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 0.9rem;
}

.position-title {
  font-style: italic;
  font-size: 0.9rem;
  color: var(--secondary-text);
  margin-bottom: var(--space-sm);
}

.accomplishment-text {
  font-size: 0.9rem;
  color: var(--secondary-text);
  line-height: 1.6;
  margin-top: auto;
}

/* Publication Section */
.publications-section {
  margin-bottom: var(--space-lg);
}

/* Dashboard Styling */
.dashboard-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 20px;
}

.stats-overview {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.stats-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px 25px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.stats-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.stats-card h5 {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #666;
}

.stats-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--accent-color);
}

.visualizations-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.chart-container {
  flex: 1;
  min-width: 250px;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.chart-title {
  font-size: 1rem;
  margin-bottom: 15px;
  text-align: center;
  color: #555;
}

/* D3 Chart Styling */
.d3-chart {
  width: 100%;
  min-height: 250px;
  margin: 0 auto;
}

/* Style D3 chart elements */
.d3-chart svg {
  overflow: visible;
}

.d3-chart .domain,
.d3-chart .tick line {
  stroke: var(--border-light);
}

.d3-chart .tick text {
  fill: var(--primary-text);
  font-size: 0.8rem;
}

.d3-chart text {
  font-family: var(--font-family-base);
}

/* Specific chart styling */
.year-chart rect {
  transition: all 0.3s ease;
}

.venue-chart rect {
  transition: all 0.3s ease;
}


/* Responsive Adjustments for Dashboard */
@media (max-width: 768px) {
  .visualizations-row {
    flex-direction: column;
  }
  
  .bar-chart {
    height: 180px;
  }
  
  .chart-item {
    width: 30px;
  }
  
  .bar {
    width: 20px;
  }
}

/* Year Dropdown Styling */
.publication-year-group {
  margin-bottom: 1rem;
}

.year-dropdown {
  background-color: white;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  overflow: hidden;
  border: 1px solid var(--border-light);
}

.year-header {
  padding: 1rem;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid var(--border-light);
}

.year-header:hover {
  background-color: #e9ecef;
}

.year-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.year-label {
  font-family: var(--font-family-serif);
  font-weight: 600;
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
  color: var(--accent-color);
}

.year-meta {
  display: flex;
  align-items: center;
  gap: 10px;
}

.publication-count {
  font-size: 0.8rem;
  padding: 4px 10px;
  background-color: var(--accent-color) !important;
}


.publication-count.journal {
  background-color: var(--bs-danger) !important; /* #dc3545 */
}

.publication-count.workshop {
  background-color: var(--bs-success) !important; /* #28a745 */
}

.publication-count.conference {
  background-color: var(--bs-primary) !important; /* #007bff */
}

.publication-count.workinggroup {
  background-color: var(--bs-secondary) !important; /* #6c757d */
}

.publication-count.preprint {
  background-color: var(--bs-warning) !important; /* #ffc107 */
}

.publication-count.poster {
  background-color: var(--bs-dark) !important; /* #343a40 */
}

.publication-count.other {
  background-color: var(--bs-info) !important; /* #17a2b8 */
}


.chevron-icon {
  display: inline-block;
  transition: transform 0.3s ease;
  font-size: 0.8rem;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
  background-color: #e9ecef;
}

.chevron-icon.expanded {
  transform: rotate(180deg);
}

.year-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  padding: 0rem;
}

.year-content.expanded {
  max-height: 5000px; /* Large enough value to accommodate all content */
  padding: 0rem;
}

.publication-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  margin: 0.75rem;
  animation: fadeIn 0.5s ease-in-out;
}

.publication-card:hover {
  transform: var(--hover-lift-sm);
  box-shadow: var(--card-shadow-hover);
}

.publication-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.publication-authors {
  font-style: italic;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.publication-venue {
  font-size: 0.85rem;
  margin-bottom: 8px;
  margin-left: 0px !important;
  color: var(--secondary-text);
}

.publication-link a {
  color: var(--link-color);
  text-decoration: none;
  font-size: 0.9rem;
  display: inline-block;
  margin-top: 5px;
}

.publication-link a:hover {
  text-decoration: underline;
}

/* Venue Badges */
.venue-badge {
  font-size: 0.7rem;
  padding: 3px 8px;
  border-radius: var(--border-radius-sm);
  margin-left: 10px;
  vertical-align: middle;
}




.venue-badge.journal {
  background-color: var(--bs-danger) !important; /* #dc3545 */
}

.venue-badge.workshop {
  background-color: var(--bs-success) !important; /* #28a745 */
}

.venue-badge.conference {
  background-color: var(--bs-primary) !important; /* #007bff */
}

.venue-badge.workinggroup {
  background-color: var(--bs-secondary) !important; /* #6c757d */
}

.venue-badge.preprint {
  background-color: var(--bs-warning) !important; /* #ffc107 */
}

.venue-badge.poster {
  background-color: var(--bs-dark) !important; /* #343a40 */
}

.venue-badge.other {
  background-color: var(--bs-info) !important; /* #17a2b8 */
}

/* Award emoji styles */
.award-emoji {
  margin-left: 10px;
  font-size: 1.1rem;
  display: inline-block;
  vertical-align: middle;
  cursor: help;
}

/* Projects Section */
.project-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
}

.project-card:hover {
  transform: var(--hover-lift);
  box-shadow: var(--card-shadow-hover);
}

.project-title {
  font-family: var(--font-family-serif);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: var(--accent-color);
}

.project-client {
  font-style: italic;
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.project-image {
  max-width: 100%;
  height: auto;
  max-height: 120px;
  border: 1px solid var(--border-light);
  padding: 5px;
  background-color: white;
  border-radius: var(--border-radius-sm);
}

.project-details {
  padding-left: 1.25rem;
  font-size: 0.9rem;
}

.project-details li {
  margin-bottom: 5px;
}

/* Workshop Cards */
.workshop-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  margin-bottom: var(--space-sm);
}

.workshop-card:hover {
  transform: var(--hover-lift-sm);
  box-shadow: var(--card-shadow-hover);
}

.workshop-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.workshop-authors {
  font-style: italic;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.workshop-venue {
  font-size: 0.85rem;
  margin-bottom: 8px;
  color: var(--secondary-text);
}

.workshop-link a {
  color: var(--link-color);
  text-decoration: none;
  font-size: 0.9rem;
  display: inline-block;
  margin-top: 5px;
}

.workshop-link a:hover {
  text-decoration: underline;
}

/* Course Website Cards */
.course-website-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
}

.course-website-card:hover {
  transform: var(--hover-lift);
  box-shadow: var(--card-shadow-hover);
}

.course-name {
  font-family: var(--font-family-serif);
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: var(--space-md);
  color: var(--accent-color);
  text-align: center;
}

/* Achievement Cards */
.achievement-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  margin-bottom: var(--space-sm);
}

.achievement-card:hover {
  transform: var(--hover-lift-sm);
  box-shadow: var(--card-shadow-hover);
}

.achievement-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 8px;
  font-style: italic;
  color: var(--accent-color);
}

.achievement-description {
  font-size: 0.9rem;
  color: var(--secondary-text);
}

/* CV Footer */
.cv-footer {
  margin-top: var(--space-lg);
  padding-top: var(--space-md);
  border-top: 1px solid var(--border-light);
}

.note {
  font-size: 0.85rem;
  font-style: italic;
  color: var(--secondary-text);
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .cv-main-title {
    font-size: 1.8rem;
  }
  
  .section-title {
    font-size: var(--font-size-lg);
  }

  .section-title.small {
    font-size: var(--font-size-md);
  }
  
  .publication-title, .workshop-title, .achievement-title, .project-title, .course-name {
    font-size: 1rem;
  }
  
  .cv-section {
    padding: 0.75rem;
  }
  
  .year-label {
    font-size: 1.2rem;
  }
  
  .publication-authors, .workshop-authors, .achievement-description, .project-details li {
    font-size: 0.85rem;
  }
  
  .year-content.expanded {
    padding: 0.75rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cv-content {
    padding: 0 var(--space-md);
  }
  
  .publication-title, .workshop-title {
    font-size: 1.05rem;
  }
}

@media (min-width: 992px) {
  .cv-section {
    padding: var(--space-lg);
  }
}
