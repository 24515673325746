/* 
 * App-specific component styles
 * This file contains styles for reusable app components that build on global styles
 */

/* Basic Layout */
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1 0 auto;
  padding-bottom: var(--space-xl);
}

/* Component Layouts */
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-section {
  margin-bottom: var(--space-xl);
}

.content-section-sm {
  margin-bottom: var(--space-lg);
}

/* Component Styles */
.rounded-3 {
  border-radius: 0.5rem !important;
}

.bg-light {
  background-color: var(--secondary-bg) !important;
}

/* Common Component Typography */
.subtitle {
  font-size: var(--font-size-md);
  color: var(--secondary-text);
  font-weight: 300;
  margin-bottom: var(--space-md);
}

.year-indicator {
  float: right;
  font-family: var(--font-family-condensed);
  color: var(--secondary-text);
}

.small-text {
  font-size: var(--font-size-sm);
  color: var(--secondary-text);
  font-weight: 300;
}

/* Reusable CV/Publications Styling */
.cv-header, .pub-header, .research-header {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-lg);
  font-variant: small-caps;
  border-bottom: 1px solid var(--border-light);
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
  color: var(--primary-text);
}

.pub-list, .cv-list {
  text-align: left;
  font-family: var(--font-family-condensed);
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  list-style-type: none;
  padding-left: 0;
}

.pub-list li, .cv-list li {
  margin-bottom: var(--space-sm);
  padding-left: var(--space-sm);
  border-left: 2px solid transparent;
  transition: var(--transition-standard);
}

.pub-list li:hover, .cv-list li:hover {
  border-left: 2px solid var(--primary-text);
  background-color: var(--highlight-bg);
}

/* News and Card Listings */
.news-card, .bio-card, .research-card {
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-color);
  overflow: hidden;
}

.news-list {
  list-style-type: none;
  font-size: var(--font-size-sm);
  padding-left: 0;
  margin-bottom: 0;
  max-height: 300px;
  overflow-y: auto;
}

.news-li {
  position: relative;
  padding: var(--space-sm) 0;
  line-height: 1.4;
}

.news-li::before {
  content: attr(date-data);
  font-weight: bold;
  display: block;
  color: var(--secondary-text);
  margin-bottom: var(--space-xs);
}

.news-li-border:not(:last-child) {
  margin-bottom: var(--space-sm);
  border-bottom: 1px solid var(--border-light);
  padding-bottom: var(--space-sm);
}

/* Footer */
.footer {
  padding: var(--space-lg) 0;
  background-color: var(--secondary-bg);
  border-top: 1px solid var(--border-color);
}

/* Media Queries */
@media (max-width: 767.98px) {
  .container, .container-sm {
    max-width: 100%;
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }
  
  h1 {
    font-size: 1.75rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1.25rem;
  }

  .cv-header, .pub-header, .research-header {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
}

/* Legacy Styles (keeping for compatibility) */
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
