/* Profile Section */
.profile-container {
  margin-bottom: var(--space-xl);
}

/* Home-specific link override for dark text */
.home-link {
  color: var(--primary-text);
}

/* News Tags */
.news-tag {
  display: inline-block;
  padding: 2px 6px;
  margin-right: 8px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  vertical-align: middle;
}

.news-tag.general {
  background-color: #6c757d; /* Gray */
}

.news-tag.grant {
  background-color: #28a745; /* Green */
}

.news-tag.paper {
  background-color: #007bff; /* Blue */
}

.news-tag.talk {
  background-color: #fd7e14; /* Orange */
}

/* Profile Elements */
.profile-image {
  max-width: 250px;
  height: auto;
  margin: 0 auto var(--space-md);
  box-shadow: var(--card-shadow-hover);
  border: 3px solid #fff;
}

.profile-name {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  margin-bottom: var(--space-sm);
  font-size: var(--font-size-xl);
}

.profile-titles {
  margin-bottom: var(--space-md);
}

.sub {
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--secondary-text);
}

.social-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--space-sm);
}

/* Bio Section */
.bio {
  font-size: var(--font-size-md);
  font-weight: 300;
  line-height: 1.6;
}

/* Title Styling */
.news-title, .bio-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  border-bottom: 1px solid var(--border-light) !important;
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
  min-height: 20px !important;
}

/* Research Section Elements */
.research-intro {
  padding: var(--space-md);
  background-color: var(--highlight-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--space-lg);
  width: 100%;
}

.research-areas {
  margin-bottom: var(--space-xl);
}

.research-icon {
  max-width: 100%;
  height: auto;
  max-height: 150px;
  margin: 0 auto;
}

.research-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  margin-bottom: 0.75rem;
  font-size: var(--font-size-lg);
}

.research-text {
  font-size: var(--font-size-sm);
  line-height: 1.5;
  color: var(--secondary-text);
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .profile-image {
    max-width: 250px;
  }
  
  .profile-name {
    font-size: var(--font-size-lg);
  }
  
  .sub {
    font-size: var(--font-size-md);
  }
  
  .bio-alignment {
    text-align: center;
    padding: 0 var(--space-md);
  }
  
  .research-intro {
    padding: 0.75rem;
  }
  
  .research-title {
    font-size: 1.1rem;
    margin-top: var(--space-sm);
  }
  
  .research-text {
    font-size: var(--font-size-sm);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .profile-image {
    max-width: 250px;
  }
  
  .bio-alignment {
    text-align: left;
    padding: 0 var(--space-md);
  }
}

@media (min-width: 992px) {
  .bio-alignment {
    text-align: left;
  }
  
  .research-intro {
    padding: var(--space-lg);
  }
}
