/* Post Structure */
.post {
  font-family: var(--font-family-serif);
}

.post-header {
  font-variant: small-caps;
  border-bottom: 1px solid var(--primary-text);
  display: flow-root;
  width: 100%;
}

.post-body {
  text-align: left;
  margin: var(--space-sm);
}

.title {
  text-align: left;
  float: left;
  width: auto;
  font-size: var(--font-size-lg);
}

.date {
  text-align: right;
  float: right;
  width: auto;
  font-size: var(--font-size-sm);
}

/* Topic Selection */
.topic-selection {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-lg);
  font-variant: small-caps;
  border-bottom: 1px solid var(--primary-text);
  margin-bottom: var(--space-sm);
  margin-top: var(--space-lg);
}

/* Topic navigation styling */
.topic-nav {
  flex-wrap: wrap;
  gap: var(--space-sm);
  margin-bottom: var(--space-lg);
}

.nav-topic {
  color: var(--light-text) !important;
  background-color: var(--dark-bg) !important;
  border-radius: var(--border-radius-sm);
  font-variant: small-caps;
  font-weight: 600;
  transition: var(--transition-standard);
  cursor: pointer;
  border: 1px solid var(--dark-bg-hover);
  letter-spacing: 0.5px;
}

.nav-topic:hover {
  color: var(--light-text-hover) !important;
  background-color: var(--dark-bg-hover) !important;
  box-shadow: var(--card-shadow);
  transform: var(--hover-lift-sm);
}

.active-topic {
  color: var(--light-text-hover) !important;
  background-color: var(--dark-bg-hover) !important;
  font-variant: small-caps;
  font-weight: 600;
  position: relative;
  border: 1px solid #4a4f53;
  letter-spacing: 0.5px;
}

.active-topic::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--light-text-hover);
}

/* Topics in blog posts */
.topics-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: var(--space-sm) 0;
}

.topic-badge {
  background-color: var(--dark-bg) !important;
  color: var(--light-text) !important;
  margin: 0 3px;
  cursor: pointer;
  font-variant: small-caps;
  transition: var(--transition-standard);
  border-radius: var(--border-radius-sm);
  padding: 6px 10px;
  border: 1px solid var(--dark-bg-hover);
  font-weight: 500;
}

.topic-badge:hover {
  background-color: var(--dark-bg-hover) !important;
  color: var(--light-text-hover) !important;
  box-shadow: var(--card-shadow);
  transform: var(--hover-lift-sm);
}

/* Blog-specific link styling */
.blog-link {
  color: var(--primary-text);
  text-decoration: none;
  font-weight: 600;
  transition: var(--transition-standard);
}

.blog-link:hover,
.blog-link:active {
  color: var(--primary-text);
}

/* Search input styling */
.input-group {
  margin-top: var(--space-lg);
}

.form-control:focus {
  border-color: #343a40;
  box-shadow: 0 0 0 0.25rem rgba(52, 58, 64, 0.25);
}

.clear-button {
  cursor: pointer;
  background-color: var(--primary-text);
  color: #e6e6e6;
  border: 1px solid #343a40;
  transition: var(--transition-standard);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.clear-button:hover {
  background-color: #343a40;
  color: #ffffff;
  box-shadow: var(--card-shadow);
}

/* Responsive adjustments */
@media screen and (max-width: 767px) {
  .profile-photo-subtitle {
    content: "";
  }

  .bio-alignment {
    text-align: center;
  }

  .topic-nav {
    justify-content: center;
  }
}

@media screen and (min-width: 767px) {
  .profile-photo-subtitle {
    content: "David H Smith IV";
  }

  .bio-alignment {
    text-align: left;
  }
}
