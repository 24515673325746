/* Teaching Content Container */
.teaching-content {
  margin-bottom: var(--space-xl);
}

/* Section Title Styling */
.section-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  border-bottom: 1px solid var(--border-light);
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
  font-size: var(--font-size-xl);
  padding-bottom: 0.5rem;
}

/* University Headers */
.university-header {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
  font-size: var(--font-size-lg);
  color: var(--primary-text);
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--accent-color);
}

/* Text Styling */
.teaching-text {
  font-size: var(--font-size-md);
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: var(--space-md);
  color: var(--secondary-text);
}

/* Blockquote Styling */
.blockquote-text {
  font-family: var(--font-family-serif);
  margin: var(--space-md) var(--space-lg);
  font-size: var(--font-size-md);
  font-style: italic;
  text-align: left;
  border-left: 3px solid var(--primary-color);
  padding: var(--space-md);
  background-color: var(--highlight-bg);
  border-radius: var(--border-radius-md);
  color: var(--secondary-text);
}

/* Coming Soon Badge */
.coming-soon-badge {
  display: inline-block;
  margin-top: var(--space-sm);
  padding: 4px 8px;
  background-color: #fd7e14;
  color: white;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: var(--border-radius-sm);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Section Containers */
.teaching-intro, .teaching-experience, .teaching-philosophy {
  padding: var(--space-md);
  background-color: var(--highlight-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--space-lg);
  width: 100%;
}

/* Courses Container */
.courses-container {
  margin-top: var(--space-md);
  margin-bottom: var(--space-lg);
}

/* Course Cards - Styled like research cards */
.course-card {
  transition: all 0.3s ease;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--border-light);
  overflow: hidden;
}

.course-card:hover {
  transform: var(--hover-lift);
  box-shadow: var(--card-shadow-hover);
}

.course-icon {
  max-width: 100%;
  height: auto;
  max-height: 120px;
  margin: 0 auto;
}

.course-title {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  margin-bottom: 0.75rem;
  font-size: var(--font-size-lg);
}

.course-description {
  font-size: var(--font-size-sm);
  line-height: 1.5;
  color: var(--secondary-text);
}

/* Philosophy List */
.philosophy-list {
  padding-left: 1.5rem;
  margin-bottom: var(--space-md);
}

.philosophy-list li {
  margin-bottom: var(--space-sm);
  font-size: var(--font-size-sm);
  color: var(--secondary-text);
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 767.98px) {
  .section-title {
    font-size: var(--font-size-lg);
  }
  
  .university-header {
    font-size: 1.1rem;
    margin-top: var(--space-md);
  }
  
  .teaching-text, .blockquote-text {
    font-size: var(--font-size-sm);
  }
  
  .teaching-intro, .teaching-experience, .teaching-philosophy {
    padding: 0.75rem;
  }
  
  .course-title {
    font-size: 1.1rem;
    margin-top: var(--space-sm);
  }
  
  .course-description {
    font-size: var(--font-size-sm);
  }
  
  .philosophy-list li {
    font-size: 0.85rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .teaching-content {
    padding: 0 var(--space-md);
  }
  
  .university-header {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) {
  .teaching-intro, .teaching-experience, .teaching-philosophy {
    padding: var(--space-lg);
  }
}