/* 
 * Global CSS Variables 
 * Main site-wide styling variables for consistent look
 */
:root {
  /* Colors */
  --primary-bg: #ffffff;
  --secondary-bg: #f8f9fa;
  --primary-text: #666;
  --secondary-text: #666;
  --accent-color: #404040;
  --border-color: #dee2e6;
  --border-light: rgba(192, 192, 192, 0.5);
  --link-color: #007bff;
  --link-hover: #0056b3;
  --code-bg: #f6f8fa;
  --card-bg: rgba(33, 37, 41, 0.75);
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --card-shadow-hover: 0 4px 8px rgba(0, 0, 0, 0.2);
  --highlight-bg: rgba(192, 192, 192, 0.2);
  --dark-bg: #212529;
  --dark-bg-hover: #343a40;
  --light-text: #e6e6e6;
  --light-text-hover: #ffffff;

  /* Spacing */
  --space-xs: 0.25rem;
  --space-sm: 0.5rem;
  --space-md: 1rem;
  --space-lg: 1.5rem;
  --space-xl: 2rem;

  /* Typography */
  --font-size-base: 14pt;
  --font-size-sm: 0.9rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  
  /* Font Families */
  --font-family-base: 'Lexend Deca', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-family-serif: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  --font-family-condensed: 'Roboto Condensed', sans-serif;
  --font-family-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

  /* Transitions */
  --transition-standard: all 0.3s ease;
  
  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  
  /* Common Effects */
  --hover-lift: translateY(-2px);
  --hover-lift-sm: translateY(-1px);
}

/* Global Reset and Base Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: var(--font-size-base);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  color: var(--primary-text);
  background-color: var(--secondary-bg);
  overflow-x: hidden;
}

code {
  font-family: var(--font-family-code);
  background-color: var(--code-bg);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 600;
  line-height: 1.2;
}

p {
  margin-top: 0;
  margin-bottom: var(--space-md);
}

/* Links */
a {
  color: var(--link-color);
  text-decoration: none;
  transition: var(--transition-standard);
  outline: none !important;
}

a:hover {
  color: var(--link-hover);
  outline: none !important;
  text-decoration: underline;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  border-radius: var(--border-radius-sm);
}

/* Cards */
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: var(--card-bg);
  color: var(--light-text);
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-serif);
  font-variant: small-caps;
}

.card-title a {
  text-decoration: none !important;
  outline: none;
  color: var(--light-text);
}

/* Section Headers */
.section-header {
  font-family: var(--font-family-serif);
  font-variant: small-caps;
  border-bottom: 1px solid var(--border-light);
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
  color: var(--primary-text);
}

/* Card Styling */
.custom-card {
  text-decoration: none !important;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  transition: var(--transition-standard);
  overflow: hidden;
}

.custom-card:hover {
  text-decoration: none !important;
  box-shadow: var(--card-shadow-hover);
}

/* List Styling */
.styled-list {
  padding-left: 0;
  list-style-type: none;
}

.styled-list li {
  padding: var(--space-sm) 0;
  border-left: 2px solid transparent;
  transition: var(--transition-standard);
}

.styled-list li:hover {
  border-left: 2px solid var(--primary-text);
  background-color: var(--highlight-bg);
  padding-left: var(--space-sm);
}

/* Container adjustments */
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Mobile-first approach */
@media (max-width: 576px) {
  html {
    font-size: 14px;
  }
  
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  a, button {
    min-height: 44px;
    min-width: 44px;
  }
}

