.navbar {
  width: 100%;
  background-color: #212529 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.navbar-brand {
  color: #ffffff !important;
  font-family: "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif; 
  font-variant: small-caps;
  font-weight: 700;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
}

.navbar-brand:hover {
  color: #ffffff !important;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  text-decoration: none;
}

.navbar-nav .nav-link {
  color: #ffffff !important;
  font-style: var(--font-family-serif);
  font-variant: small-caps;
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0.25rem;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
  text-decoration: none;
}

.navbar-nav .nav-link:hover, 
.navbar-nav .nav-link:focus {
  color: #ffffff !important;
  font-variant: small-caps;
  background-color: #343a40;
  border: 1px solid #4a4f53;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.navbar-toggler {
  border-color: rgba(192, 192, 192, 0.5) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(192, 192, 192, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

/* Mobile responsive adjustments */
@media screen and (max-width: 767px) {
  .navbar-brand {
    font-size: 1.5rem;
  }
  
  .navbar-collapse {
    background-color: #212529;
    padding: 0.5rem;
    border-radius: 0 0 4px 4px;
  }
  
  .navbar-nav .nav-link {
    text-align: center;
    margin: 0.25rem 0;
    padding: 0.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-brand {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 992px) {
  .navbar-brand {
    font-size: 2rem;
  }
  
  .navbar-nav .nav-link {
    margin: 0 0.5rem;
  }
}
